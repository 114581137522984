.text-center {
    text-align: center;
}

.text-bold {
    font-weight: 600;
}

.text-grey {
    color: var(--primary-color-gray);
}

.text-primary {
    color: var(--primary-color);
}

.text-error {
    font-size: 14px;
    color: var(--error-color);
}

//Headings
.h1 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 600;
}

.h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
}

.h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
}

.h4 {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
}

.h5 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.h6 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

//Margin Top
.mt-1 {
    margin-top: 8px;
}

.mt-2 {
    margin-top: 16px;
}

.mt-3 {
    margin-top: 24px;
}

.mt-4 {
    margin-top: 32px;
}

.mt-5 {
    margin-top: 40px;
}

.mt-6 {
    margin-top: 48px;
}

.mt-7 {
    margin-top: 56px;
}

.mt-8 {
    margin-top: 64px;
}

.mt-9 {
    margin-top: 72px;
}

.mt-10 {
    margin-top: 80px;
}

.mt-11 {
    margin-top: 88px;
}

.mt-12 {
    margin-top: 96px;
}

//Margin Left
.ml-1 {
    margin-left: 8px;
}

.ml-2 {
    margin-left: 16px;
}

.ml-3 {
    margin-left: 24px;
}

.ml-4 {
    margin-left: 32px;
}

.ml-5 {
    margin-left: 40px;
}

.ml-6 {
    margin-left: 48px;
}

.ml-7 {
    margin-left: 56px;
}

.ml-8 {
    margin-left: 64px;
}

.ml-9 {
    margin-left: 72px;
}

.ml-10 {
    margin-left: 80px;
}

.ml-11 {
    margin-left: 88px;
}

.ml-12 {
    margin-left: 96px;
}

//Margin Right
.mr-1 {
    margin-right: 8px;
}

.mr-2 {
    margin-right: 16px;
}

.mr-3 {
    margin-right: 24px;
}

.mr-4 {
    margin-right: 32px;
}

.mr-5 {
    margin-right: 40px;
}

.mr-6 {
    margin-right: 48px;
}

.mr-7 {
    margin-right: 56px;
}

.mr-8 {
    margin-right: 64px;
}

.mr-9 {
    margin-right: 72px;
}

.mr-10 {
    margin-right: 80px;
}

.mr-11 {
    margin-right: 88px;
}

.mr-12 {
    margin-right: 96px;
}

//Margin Bottom
.mb-1 {
    margin-bottom: 8px;
}

.mb-2 {
    margin-bottom: 16px;
}

.mb-3 {
    margin-bottom: 24px;
}

.mb-4 {
    margin-bottom: 32px;
}

.mb-5 {
    margin-bottom: 40px;
}

.mb-6 {
    margin-bottom: 48px;
}

.mb-7 {
    margin-bottom: 56px;
}

.mb-8 {
    margin-bottom: 64px;
}

.mb-9 {
    margin-bottom: 72px;
}

.mb-10 {
    margin-bottom: 80px;
}

.mb-11 {
    margin-bottom: 88px;
}

.mb-12 {
    margin-bottom: 96px;
}

//Padding Top
.pt-1 {
    padding-top: 8px;
}

.pt-2 {
    padding-top: 16px;
}

.pt-3 {
    padding-top: 24px;
}

.pt-4 {
    padding-top: 32px;
}

.pt-5 {
    padding-top: 40px;
}

.pt-6 {
    padding-top: 48px;
}

.pt-7 {
    padding-top: 56px;
}

.pt-8 {
    padding-top: 64px;
}

.pt-9 {
    padding-top: 72px;
}

.pt-10 {
    padding-top: 80px;
}

.pt-11 {
    padding-top: 88px;
}

.pt-12 {
    padding-top: 96px;
}

//Padding Left
.pl-1 {
    padding-left: 8px;
}

.pl-2 {
    padding-left: 16px;
}

.pl-3 {
    padding-left: 24px;
}

.pl-4 {
    padding-left: 32px;
}

.pl-5 {
    padding-left: 40px;
}

.pl-6 {
    padding-left: 48px;
}

.pl-7 {
    padding-left: 56px;
}

.pl-8 {
    padding-left: 64px;
}

.pl-9 {
    padding-left: 72px;
}

.pl-10 {
    padding-left: 80px;
}

.pl-11 {
    padding-left: 88px;
}

.pl-12 {
    padding-left: 96px;
}

//Padding Right
.pr-1 {
    padding-right: 8px;
}

.pr-2 {
    padding-right: 16px;
}

.pr-3 {
    padding-right: 24px;
}

.pr-4 {
    padding-right: 32px;
}

.pr-5 {
    padding-right: 40px;
}

.pr-6 {
    padding-right: 48px;
}

.pr-7 {
    padding-right: 56px;
}

.pr-8 {
    padding-right: 64px;
}

.pr-9 {
    padding-right: 72px;
}

.pr-10 {
    padding-right: 80px;
}

.pr-11 {
    padding-right: 88px;
}

.pr-12 {
    padding-right: 96px;
}

//Padding Bottom
.pb-1 {
    padding-bottom: 8px;
}

.pb-2 {
    padding-bottom: 16px;
}

.pb-3 {
    padding-bottom: 24px;
}

.pb-4 {
    padding-bottom: 32px;
}

.pb-5 {
    padding-bottom: 40px;
}

.pb-6 {
    padding-bottom: 48px;
}

.pb-7 {
    padding-bottom: 56px;
}

.pb-8 {
    padding-bottom: 64px;
}

.pb-9 {
    padding-bottom: 72px;
}

.pb-10 {
    padding-bottom: 80px;
}

.pb-11 {
    padding-bottom: 88px;
}

.pb-12 {
    padding-bottom: 96px;
}

//Separators
.separator {
    border-bottom: 1px solid #ebebeb;
}

.separator-1 {
    margin: 8px 0;
    border-bottom: 1px solid #ebebeb;
}

.separator-2 {
    margin: 16px 0;
    border-bottom: 1px solid #ebebeb;
}

.separator-3 {
    margin: 24px 0;
    border-bottom: 1px solid #ebebeb;
}

.separator-4 {
    margin: 32px 0;
    border-bottom: 1px solid #ebebeb;
}

.separator-5 {
    margin: 40px 0;
    border-bottom: 1px solid #ebebeb;
}

.separator-6 {
    margin: 48px 0;
    border-bottom: 1px solid #ebebeb;
}
