.checkout {
    @media (max-width: 744px) {
        padding: 24px;
    }

    .order-wrapper {
        .your-order-title {
            display: flex;
            justify-content: space-between;

            .checkout-close-button {
                fill: white;
            }
        }

        .checkout-promotion-information {
            position: relative;
            background-color: var(--primary-color);
            color: white;
            border-radius: 6px;
            border: 1px solid rgb(118, 118, 118);

            .checkout-promotion-information-heading {
                display: flex;
                justify-content: space-between;
            }

            .checkout-promotion-information-items {
                font-size: 14px;
                line-height: 1.5;
            }

            .checkout-remove-promotion {
                position: absolute;
                top: -10px;
                right: -10px;
                background-color: white;
                border-radius: 50%;
                padding: 8px;
                border: 1px solid rgb(118, 118, 118);
                cursor: pointer;
            }
        }

        .order {
            .order-item {
                .edit-link {
                    font-size: 14px;
                }

                .remove-link {
                    font-size: 14px;
                }

                .detail {
                    .modifier-name {
                        font-weight: 400;
                    }
                }
            }
        }

        .complete-order-button {
            width: 100%;
        }

        .checkout-discount-wrapper {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }

        .checkout-total-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }
}

// .checkout {
//     height: 100vh;
//     display: flex;
//     flex-direction: column;

//     .order-wrapper {
//         display: flex;
//         flex: 1;
//         overflow: scroll;

//         /* Hide scrollbar for Chrome, Safari and Opera */
//         .order::-webkit-scrollbar {
//             display: none;
//         }

//         .cart-empty {
//             height: 100vh;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             flex-direction: column;

//             .icon {
//                 color: hsl(0, 0%, 80%);
//             }
//         }

//         .order {
//             overflow: scroll;
//             padding: 0 84px 84px 84px;
//             width: 100%;

//             /* Hide scrollbar for IE, Edge and Firefox */
//             -ms-overflow-style: none; /* IE and Edge */
//             scrollbar-width: none; /* Firefox */

//             .title {
//                 display: flex;
//                 align-items: center;

//                 .image {
//                     height: 72px;
//                 }
//             }

//             .checkout-order-type {
//                 display: grid;
//                 grid-template-columns: 1fr auto;
//                 align-items: center;
//             }

//             .checkout-table-number {
//                 display: grid;
//                 grid-template-columns: 1fr auto;
//                 align-items: center;
//             }

//             .order-item {
//                 display: grid;
//                 grid-template-columns: auto 1fr auto;
//                 grid-column-gap: 32px;
//                 align-items: center;

//                 .remove-button {
//                     background-color: #ffffff;
//                     color: #484848;
//                     border: 1px solid #e0e0e0;
//                     padding: 10px 18px;
//                     font-weight: 300;
//                 }

//                 .detail {
//                     display: grid;
//                     grid-auto-rows: auto; /* shrink to min size*/
//                     grid-row-gap: 4px;

//                     .name-edit-button {
//                         display: flex;
//                     }

//                     .edit-button {
//                         background-color: #ffffff;
//                         color: #484848;
//                         border: 1px solid #e0e0e0;
//                         display: inline-block;
//                         padding: 10px 18px;
//                         font-weight: 300;
//                     }
//                 }
//             }
//         }
//     }

//     .footer {
//         padding: 24px;
//         border-top: 1px solid #e0e0e0;

//         .checkout-buttons-container {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//         }

//         .order-more-button {
//             background-color: #ffffff;
//             color: #484848;
//             border: 1px solid #e0e0e0;
//             width: 350px;
//             font-size: 22px;
//         }

//         .complete-order-button {
//             width: 350px;
//         }

//         .cancel-button {
//             background-color: #ffffff;
//             color: #484848;
//             border: 1px solid #e0e0e0;
//             padding: 16px 30px;
//             font-weight: 300;
//         }

//         .pay-later-link {
//             display: flex;
//             justify-content: center;
//         }
//     }
// }

// .payment-modal {
//     height: 100vh;
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     text-align: center;
//     padding: 128px 200px;

//     .issue-fixed-button {
//         background-color: #ffffff;
//         color: #484848;
//         border: 1px solid #e0e0e0;
//         padding: 12px 24px;
//     }

//     .awaiting-card-text {
//         line-height: 42px;
//     }

//     .awaiting-card-image {
//         height: 250px;
//     }

//     .order-number {
//         font-size: 200px;
//         line-height: 256px;
//     }

//     .redirecting-in-text {
//         margin-top: auto;
//     }

//     .retry-buttons {
//         display: flex;
//     }

//     .retry-cancel-button {
//         background-color: #ffffff;
//         color: #484848;
//         border: 1px solid #e0e0e0;
//     }
// }
